* {
  box-sizing: border-box;
}

:root {
  --primary-color: rgb(86, 109, 164);
  --secondary-color: rgba(125, 36, 14);
  --tertiary-color: rgba(103, 125, 14, 0.7);
  --light-grey: rgb(245,245,245);
  --alt-blue: rgb(88, 115, 175);
  --border-color: rgb(60, 60, 60);
}

h1,h2,h3, h4, h5, h6, a, p, span, li {
  font-family: "Montserrat", sans-serif;
}

/* Styling for navigation bar */
.new-navigation-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  z-index: 1;
}

.new-navigation-container > a {
  text-decoration: none;
  color: white;
  font-size: 48px;
}

.new-navigation-container > button {
  all: unset;
  color: white;
  font-size: 48px;
}

.new-navigation-container > button:hover {
  cursor: pointer;
}

.nav-pop-up {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--primary-color);
}

.nav-pop-up > button {
  all: unset;
  color: white;
  font-size: 48px;
  align-self: flex-end;
  padding: 2rem;
}

.nav-links-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding-left: 3rem;
}

#pop-up-socials-wrapper {
  display: flex;
  padding: 2rem 3rem;
  width:300px;
  justify-content: space-between;
}

#pop-up-socials-wrapper > a {
  color: white;
  font-size: 48px;
}

#cv-icon {
  position: relative;
}

#cv-icon > p {
  text-decoration: none;
  font-size: 1rem;
  color: var(--primary-color);
  font-weight: bold;
  position: absolute;
  top: 0;
  transform: translate(57%, 30%);
}

#nav-button {
  all: unset;
}

#nav-link {
  color: white;
  text-decoration: none;
}

#down-arrow {
  color: white;
  font-size: 48px;
  margin: 1rem;
  transform: translateY(-100px)
}

/* Styling for Full Page Header */
.full-page-header-container {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  width: 100%;
  height: calc(100vh - 1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.full-page-header-container > h1 {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: calc(2rem + 1vw);
}

.full-page-header-container > h2 {
  font-size: 1rem;
}

#home-page-header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem;
  transform: translateY(-10%)
}

#home-page-header-wrapper > h1 {
  color: white;
  margin: 0;
  font-size: calc(1rem + 4vw)
}

#home-page-header-wrapper > h3 {
  color: rgb(210,210,210);
  margin: 0;
  font-size: calc(1rem + 1vw)
}

#dave-head {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: var(--light-grey)
}

/* Styling for home page */

.new-home-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.main-content-container {
  margin-top: 10vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Styling for introduction section */

.introduction-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
  gap: 1rem;
  padding-bottom: 2rem;
}

.introduction-container > img {
  height: 150px;
  width: 150px;
  margin-bottom: 3rem;
  border-radius: 50%;
  background-color: var(--primary-color)
}

.introduction-container > .introduction-text {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  color: var(--secondary-color)
}

.introduction-text > p {
  font-size: 18px;
  color: black;
}

.introduction-text > h1 {
  font-size: x-large;
}

/* Stlying for more info section */
.more-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}

.devices-image {
  width: 80%;
  max-width: 80%;
  aspect-ratio: 2.5/1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.more-info-content {
  background-color: var(--primary-color);
  width: 100%;
  height: fit-content;
  color: white;
}

.more-info-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 6rem;
  gap: 1rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5em;
}

.more-info-text > p {
  padding-left: 2rem;
  padding-right: 2rem;
}

.more-info-text > p > a {
  color: white;
}

.more-info-text > a {
  color: white;
  font-size: 0.9rem;
}

/* Styling for experience section */
.experience-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  gap: 1rem;
  background-color: var(--light-grey);
}

.experience-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  max-width: 95%;
  height: fit-content;
  border: solid 1px var(--border-color);
  border-radius: 5px;
  background-color: white;
  transform: translateY(-12%);
}

.experience-icon {
  background-color: var(--secondary-color);
  color: white;
  padding: 1rem;
  border-radius: 50%;
  margin-top: 2rem;
}

.experience-text {
  padding-left: 2rem;
  padding-right: 2rem;
}

.languages-container {
  font-size: 0.9rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-bottom: 2rem;
}

.languages-container > h3 {
  color: var(--primary-color);
}

/* Styling for Projects section */

.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  background-color: var(--light-grey);
}

.projects-text {
  text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;
}

.project-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.project-card {
  display: flex;
  justify-content: center;
  min-width: 320px;
  min-height: 240px;
  max-height: 240px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 7.5%;
  box-shadow: 1px 1px 5px 1px #888888;
  transition: box-shadow 200ms ease-in-out;
  position: relative;
}

.project-title {
  width: 320px;
  height: 60px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  text-align: center;
  background-color: rgba(0,0,0,0.6);
  border-radius: 0px 0px 10px 10px;
  color: white;
  transition: all 500ms ease-in-out;
}

.project-title > h2 {
  font-size: large;
  margin: 1rem;
}

.project-hover-content {
  position: absolute;
  align-self: flex-start;
  height: 240px;
  width: 320px;
  color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  opacity: 0;
  transition: all 800ms ease-in-out
}

.project-card:hover .project-title {
  opacity: 0;
  height: 0%;
}

.project-card:hover .project-hover-content {
  opacity: 1;
  height: 240px;
}

.project-hover-content > h2 {
  margin: 1rem;
}

.project-hover-content > p {
  margin-top: 0;
  font-size: 0.9rem;
}

.project-hover-content > .icons-container {
  display: flex;
  font-size: x-large;
  gap: 0.5rem;
}

.project-hover-content > .icons-container > .tech-icon {
  border: solid 1px black;
  border-radius: 25%;
  padding: 2px;
  background-color: rgb(255,255,255, 0.9);
}

.project-hover-content > a {
  all: unset;
  background-color: white;
  font-weight: bold;
  padding: 0.6rem;
  border-radius: 10px;
  margin: 1rem;
  transition: transform 200ms ease-in-out;
}

.project-hover-content > a:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.project-card:hover {
  box-shadow: 1px 1px 5px 4px #888888;
}

/* temporary styling whilst project is in development */
#dr-john-button {
  all: unset;
  background-color: rgb(245, 245, 245);
  font-weight: bold;
  padding: 0.6rem;
  border-radius: 10px;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#dr-john-button > a:hover {
  cursor: default;
  transform: none;
}

.project-card:hover > .project-title > h2 {
  display: none;
}

#festive500-button {
  color: rgb(28,30,61);
}

#dr-john-button {
  color: rgb(58, 75, 113)
}

#lexicon-button {
  color: rgb(83,141,78);
}

#gms-button {
  color: rgb(23,15,102);
}

#bean-button {
  color: rgb(11,168,211);
}

#tastebook-button {
  color: rgb(164,118,166);
}

/* Styling for About Me component */
.about-me-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: black;
}

.about-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;;
  min-height: 100vh;
  width: 100%;
  background-color: var(--light-grey);
}

.about-text-container > h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  margin-top: 0;
}

.about-text-container > h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  margin-top: 0;
}

.about-text-container > p {
  margin: 0;
  padding: 1rem 0rem;
  border-top: 1px solid rgb(230,230,230)
}

.image-wrapper {
  background-color: var(--primary-color);
  width: 100%;
  height: 100vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#profile-pic {
  background-position: 0% 0%;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

#henley-eight {
  background-position: 50%;
  width: 100%;
  height: 50vw;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 2rem;
}

#sport-career {
  background-color: var(--primary-color);
  color: white;
}

#skills {
  gap: 1rem;
}

#skills > h1 {
  margin: 0;
}

.skills-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
}

.skills-wrapper > p, .skills-wrapper > h3 {
  padding: 0;
  margin: 0;
  border: none;
}

.skills-wrapper > .icons-container {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}

#js-icon {
  background-color: black;
}

@media (min-width: 481px) {
  .about-me-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .about-text-container {
    width:50%;
    padding: 2rem 10vw;
  }

  .image-wrapper {
    width: 50%;
    height: 100vh;
    background-color: var(--alt-blue);
  }

  #profile-pic {
    max-width: 40vw;
    max-height: 40vw;
  }

  #henley-eight {
    max-width: 40vw;
    max-height: 20vw;
  }

  #my-journey {
    width: 100%;
    padding: 3rem;
    justify-content: flex-start;
    min-height: fit-content;
  }

  #my-journey > h1 {
    padding: 0 1rem;
  }

  #my-journey-wrapper {
    display: flex;
  }

  #my-journey-wrapper > p {
    width: 25%;
    margin: 1rem 0;
    padding: 1rem;
    border-right: solid 1px var(--border-color)
  }

  #my-journey-wrapper > p:last-child {
    border: none;
  }

  #my-journey-wrapper > p > a {
    color: var(--alt-blue)
  }
}

/* Styling for Contact component */
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 100%;
}

.contact-details-container {
  width: 100%;
  height: 100vh;
  background-color: var(--light-grey);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding: 2rem;
}

.contact-details-container > .get-in-touch-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  padding-bottom: 2rem;
}

.contact-details-container > .get-in-touch-wrapper > h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  margin: 0;
}

.contact-detail {
  width: 100%;
  padding: 10px 0px;
  border-top: 1px solid rgb(230,230,230);
  color: black;
  text-decoration: none;
}

.contact-detail:last-child {
  border-bottom: 1px solid rgb(230,230,230)
}

.contact-detail > h3 {
  margin: 10px 0px 
}

.contact-detail > p {
  margin: 10px 0px;
  color: rgb(100,100,100)
}

#desktop-view-number {
  display: none;
}

@media (min-width: 481px) {
  .contact-details-container {
    padding: 10vw;
    gap: 10vw;
  }
  .get-in-touch-wrapper {
    width: 100%;
    padding: none;
  }

  .get-in-touch-wrapper > h1 {
    font-size: 64px;
  }

  #contact-details-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .contact-detail {
    width: 30%;
    border: none;
    text-align: center;
    border: none;
    border-right: solid 1px rgb(230,230,230)
  }

  #desktop-view-number {
    display: block;
  }

  #mobile-view-number {
    display: none;
  }

  .contact-detail:last-child {
    border: none;
  }
}

/* Styling for footer component in mobile view */
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: fit-content;
  background-color: var(--primary-color);
}

.footer-container > #download-cv-button {
  padding: 1rem;
  margin-top: 1rem;;
}

.footer-container > .contact-icons {
  display: flex;
  justify-content: center;
  color: white;
  gap: 2rem;
  padding: 1rem;
}

.footer-container > .contact-icons > a {
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: solid 3px rgba(255,255,255,0.5);
  border-radius: 50%;
  padding: 0.5rem;
  font-size: large;
}

.footer-container > .built-by-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  padding: 1rem;
  line-height: 0.1;
}

.footer-container > .built-by-text > .built-in-react {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

#ftr-react-icon {
  font-size: x-large;
}

.footer-nav-links {
  display: flex;
}

.footer-nav-links > a {
  color: white;
  padding: 1rem;
}

/* Styling changes for when screen is wider than 480px (desktop view) */

@media (min-width: 481px) {
  .home-container {
    flex-direction: row;
  }

  #socials-wrapper {
    transform: translateX(-10%)
  }

  .nav-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 3rem;
    padding: 2rem 0rem;
    width: 20vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
  }

  .nav-container > .home-icon {
    padding: 1rem 2rem;
  }

  .nav-container > .desktop {
    display: flex;
    overflow: hidden;
  }

  .nav-container > .mobile {
    display: none;
  }

  .main-content-container {
    width: 65vw;
    min-width: 600px;
    overflow: scroll;
    margin-left: 20vw;
    margin-top: 0;
    border-left: solid 1px var(--border-color);
    border-right: solid 1px var(--border-color);
  }

  .devices-image {
    max-width: 500px;
  }

  .more-info-text > p {
    padding-left: 20vw;
    padding-right: 20vw;
  }

  .experience-container {
    gap: 2rem;
  }

  .experience-card {
    max-width: 30%;
    min-width: 320px;
    min-height: 480px;
  }

  .project-cards-container{
    max-width: 80vw;
  }

  #project-detail-prompt {
    display: none;
  }
}