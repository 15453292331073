:root {
  --calculator-color1: rgb(23, 15, 102);
  --calculator-color2: rgb(211, 211, 211);
  --tertiary-color: rgb(207, 181, 59);
  --label-width: 120px;
  --input-width: 180px;
}

* {
  box-sizing: border-box;
}

.gms-calculator-container {
  margin-top: 10vh;
}

/* Styling for Header */
.App-header {
  background-color: var(--calculator-color1);
  height: 15vh;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 1vmin);
}

.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;
  align-items: center;
  width: 30vw;
  color: whitesmoke;
  white-space: nowrap;
}

.percentages:hover #percent-icon {
  color: var(--tertiary-color)
}

.percentages:hover .percentage-data {
  display: flex;
}

.percentage-data {
  display: none;
  flex-direction: column;
  position: absolute;
  text-align: center;
  align-items: center;
  top: 12%;
  left: 50%;
  transform: translateX(-50%);
  height: 60vh;
  min-height: 375px;
  width: 250px;
  border: solid 2px black;
  border-radius: 2px;
  background-color: whitesmoke;
  z-index: 1;
  color: black;
  font-size: 0.8rem;
}

.percentage-data > h2 {
  font-size: 16px;
  width: 100%;
}

.GMT-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px black;
  width: 95%;
}

.GMT-wrapper > .GMT-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.GMT-row:not(:first-child) {
  border-top: solid 1px black;
}

.GMT-box {
  padding: 2px;
  text-align: center;
  flex: 1 1 0;
}

.GMT-title {
  font-weight: bold;
}

.GMT-box:not(:first-child) {
  border-left: solid 1px black;
}

.help:hover #help-icon {
  color: var(--tertiary-color)
}

.help:hover .help-content {
  display: flex;
}

.help-content {
  display: none;
  flex-direction: column;
  position: absolute;
  text-align: center;
  top: 12%;
  left: 50%;
  transform: translateX(-50%);
  height: 60vh;
  min-height: 375px;
  width: 250px;
  border: solid 2px black;
  border-radius: 2px;
  background-color: whitesmoke;
  color: black;
  z-index: 1;
  font-size: 0.8rem;
}

.help-content > h2 {
  font-size: 16px;
}

.help-content > p {
  padding: 3px;
  min-width: 240px;
  white-space: normal;
}

.help-content > footer {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 9px;
}

.help-content > footer > a {
  display: flex;
  gap: 0.5rem;
  text-decoration: none;
  color: var(--calculator-color1);
}

/* Styling for input and output boxes */

main {
  align-items: center;
  background-color: var(--calculator-color2);
  height: 85vh;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
}

.boat-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10vh;
  margin-top: 1rem;
}

.boat-type > p {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  background-color: whitesmoke;
  font-size: calc(8px + 1vh);
  height: 100%;
  width: var(--input-width);
}

.dropdown {
  position: relative;
  display: inline-block;
  height: 100%;
  width: var(--label-width);
}

.dropbtn {
  background-color: var(--calculator-color1);
  color: white;
  padding: 1rem;
  font-size: calc(10px + 1vh);
  height: 100%;
  border: none;
  width: 100%;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.dropdown > .mens-boats {
  display: none;
  position: absolute;
  left: 0%;
  background-color: whitesmoke;
  min-width: 80px;
  border: solid 1px #ddd;
  border-right: none;
  z-index: 1;
}

.dropdown > .womens-boats {
  display: none;
  position: absolute;
  left: 65%;
  background-color: whitesmoke;
  min-width: 80px;
  border: solid 1px #ddd;
  z-index: 1;
}

.dropdown-content > button:first-child {
  border: none;
}

.dropdown-content > button {
  all: unset;
  background-color: whitesmoke;
  color: black;
  text-align: center;
  font-size: 0.9rem;
  padding: 12px 16px;
  display: block;
  border-top: solid 1px #ddd;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 1;
}

.dropdown-content > button:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}

.dropdown:hover .dropbtn {
  background-color: rgba(24, 15, 102, 0.69);
}

.label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--calculator-color1);
  text-align: center;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: calc(10px + 1vh);
  border: none;
  width: var(--label-width);
}

.distance {
  display: flex;
  flex-direction: row;
  height: 10vh;
}

.distance > .input-wrapper {
  height: 100%;
  width: var(--input-width);
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  background-color: whitesmoke;
  border: none;
  font-size: calc(8px + 1vh);
}

.distance > .input-wrapper > input {
  width: 142px;
  text-align: center;
  background-color: white;
  border: solid 1px gray;
  border-radius: 4px;
  color: black;
}

.time {
  display: flex;
  flex-direction: row;
  height: 10vh;
}

.time > .input-wrapper {
  height: 100%;
  width: var(--input-width);
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: whitesmoke;
  border: none;
  font-size: calc(8px + 1vh);
}

.time > .input-wrapper > .semi-colon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.time > .input-wrapper > input {
  width: 40px;
  text-align: center;
  background-color: white;
  border: solid 1px gray;
  border-radius: 4px;
  color: black;
}

.output-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--tertiary-color);
  text-align: center;
  color: var(--calculator-color1);
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: calc(10px + 1vh);
  border: none;
  width: var(--label-width);
}

.speed {
  display: flex;
  flex-direction: row;
  height: 10vh;
}

.speed > .speed-display {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  background-color: whitesmoke;
  color: var(--calculator-color1);
  font-weight: bold;
  font-size: calc(8px + 1vh);
  height: 100%;
  width: var(--input-width);
}

.gms {
  display: flex;
  flex-direction: row;
  height: 10vh;
}

.gms > .gms-display {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  background-color: whitesmoke;
  color: var(--calculator-color1);
  font-weight: bold;
  font-size: calc(8px + 1vh);
  height: 100%;
  width: var(--input-width);
}

@media (min-width: 480px) {
  .gms-calculator-container {
    width: 65%;
    margin-top: 0;
    margin-left: 20vw;
    margin-right: 15vw;
  }

  .calculator-container {
    width: 100%;
  }
}