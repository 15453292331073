html,
body {
  background: white;
  font-family: Arial, Helvetica, sans-serif;
}

:root {
  --wrong: #3a3a3c;
  --close: #b59f3b;
  --correct: #538d4e;
}

.lexicon-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  margin-bottom: 50px;
  border-top: solid 1px var(--border-color);
  border-bottom: solid 1px var(--border-color)
}

.logo-title {
  display: flex;
}

.title {
  /* margin-top: 10px; */
  align-self: center;
  font-size: x-large;
  font-weight: bold;
}

.logo {
  align-self: center;
  height: 40px;
  
}

.grid {
  display: flex;
  flex-direction: row;
  gap: 2px;
  margin: 2px;
}

.box {
  width: 15vw;
  height: 15vw;
  border: 2px solid;
  border-color: rgb(164, 164, 164);
  display: grid;
  place-items: center;
  font-size: 2.4rem;
  text-transform: uppercase;
}

.box-empty {
  text-transform: uppercase;
  color: black
}

@keyframes wrongBox {
  from {background-color: white;}
  to {background-color: var(--wrong);}
}

@keyframes closeBox {
  from {background-color: white;}
  to {background-color: var(--close);}
}

@keyframes correctBox {
  from {background-color: white;}
  to {background-color: var(--correct);}
}

.box-wrong {
  background-color: var(--wrong);
  color: white;
  animation-name: wrongBox;
  animation-duration: 4s;
}

.box-close {
  background-color: var(--close);
  color: white;
  animation-name: closeBox;
  animation-duration: 4s;
}

.box-correct {
  background-color: var(--correct);
  color: white;
  animation-name: correctBox;
  animation-duration: 4s;
}

.keyboard-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 95%;
}

.keyboard-top {
  margin-top: 20px;
  display: inline-grid;
  grid-template-columns: repeat(10, auto);
  gap: 6px;
  margin-bottom: 4px;
  /* width: 100%; */
  
}
.keyboard-middle {
  margin: 4px;
  display: inline-grid;
  grid-template-columns: repeat(9, auto);
  gap: 6px;
  /* width: 90%; */
}
.keyboard-bottom {
  margin-bottom: 20px;
  display: inline-grid;
  grid-template-columns: repeat(9, auto);
  gap: 6px;
  margin-top: 4px;
  /* width: 100%; */
}

.typed-input {
  margin: 20px;
}

@keyframes wrongLetter {
  from {background-color: lightgray;}
  to {background-color: var(--wrong);}
}

@keyframes closeLetter {
  from {background-color: lightgray;}
  to {background-color: var(--close);}
}

@keyframes correctLetter {
  from {background-color: lightgray;}
  to {background-color: var(--correct);}
}

@keyframes textChange {
  from {color: black;}
  to {color: white}
}

.key {
  all: unset;
  height: 12vw;
  width: 8vw;
  font-size: medium;
  border-radius: 3px;
  padding: 0px;
  text-align: center;
}

.key-standard {
  border-color: lightgray;
  background-color: lightgray;
  text-transform: uppercase;
  color: black;
  font-weight:bold;
  text-transform: uppercase;
  font-weight: bold;
}

.key-wrong {
  color: white;
  border-color: var(--wrong);
  background-color: var(--wrong);
  animation: wrongLetter 2s, textChange 2s;
}

.key-close {
  color: white;
  border-color: var(--close);
  background-color: var(--close);
  animation: closeLetter 2s, textChange 2s;
}

.key-correct {
  color: white;
  border-color: var(--correct);
  background-color: var(--correct);
  animation: correctLetter 2s, textChange 2s;
}

.key-action {
  all: unset;
  height: 12vw;
  width: 12vw;
  font-size: medium;
  text-align: center;
  border-radius: 3px;
  padding: 1px;
  border-color: lightgray;
  background-color: lightgray;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
}

.footer {
  text-align: center;
}

@keyframes fade-in {
  0%{opacity: 0}
  100%{opacity: 1}
}

.popup {
  position: absolute;
  margin-top: 80px;
  z-index: 1;
  border-color: black;
  border-radius: 20px;
  background-color: white;
  border-color: black;
  border-style: solid;
  width: 350px;
  height: 470px;
  animation-name: fade-in;
  animation-duration: 3s;
}

.popup-content {
  position: relative;
  text-align: center;
  padding: 10px;
}

.popup-close {
  all: unset;
  position: absolute;
  z-index: 1;
  margin: 10px;
  padding: 0.3rem;
  top: 0;
  right: 0;
  font-weight: bold;
  background-color: #ededed;
  border-radius: 50%;
  border: solid 2px black;
  width: 20px;
  height: 20px;
  text-align: center;
}

#popup-close:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.popup-winner {
  font-size: large;
}

.popup-loser {
  font-size: large;
}

.popup-jobstuff {
  position: relative;
  margin-top: 50px;
  font-style: italic;
  font-size: medium;
}

.rules-popup {
  position: absolute;
  margin-top: 55px;
  height: 30px;
  width: 160px;
  background-color: rgb(51, 51, 51);
  border-radius: 10px;
  line-height: 30px;
  text-align: center;
  color: white;
  font-size: small;
  font-weight: bold;
  animation: fade-in 1s
}

.info-popup {
  position: absolute;
  margin-top: 60px;
  z-index: 1;
  border-color: black;
  border-radius: 20px;
  background-color: white;
  border-color: black;
  border-style: solid;
  width: 350px;
  height: 500px;
  animation-name: fade-in;
  animation-duration: 3s;
}

.info-button {
  font-size: large;
  font-weight: bold;
  border-style: solid;
  border-color: black;
  color: black;
  border-radius: 50%;
  background-color: white;
  height: 30px;
  width: 30px;
}

.info-button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.info-box {
  padding: 5px;
}

.how-to-play {
  font-size: x-large;
  font-weight: bold;
}

.instructions {
  text-align: left;
  font-size: medium;
}

.info-popup-jobstuff {
  position: absolute;
  text-align: center;
  bottom: 5px;
  width: 330px;
  left: 10px;
  font-style: italic;
  font-size: small;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
}

.examples {
  margin-top: 5px;
  font-size:small;
  font-weight: bold;
  text-align: left;
  padding: 0px;

}

.example-box-empty {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-color: rgb(164, 164, 164);
  display: grid;
  place-items: center;
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
  color: black
}

.example-box-correct {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-color: rgb(164, 164, 164);
  display: grid;
  place-items: center;
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background-color: var(--correct);
  animation-name: correctBox;
  animation-duration: 6s;
}

.example-box-close {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-color: rgb(164, 164, 164);
  display: grid;
  place-items: center;
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background-color: var(--close);
  animation-name: closeBox;
  animation-duration: 6s;
}

.example-box-wrong {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-color: rgb(164, 164, 164);
  display: grid;
  place-items: center;
  font-size: small;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  background-color: var(--wrong);
  animation-name: wrongBox;
  animation-duration: 6s;
}

.grid-description {
  display: flex;
  justify-content: flex-start;
  font-size: x-small;
  gap: 10px;
}

.hardmode-instructions {
  font-size: small;
  font-weight: bold;
  margin-top: 10px;
}

@media (min-width: 480px) {
  .title-container {
    margin-top: 0;
    max-width: 550px;
    padding-bottom: 0.5rem;
  }

  .logo-title {
    margin-top: 10px;
  }

  .rules-popup {
    margin-top: 70px;
  }

  .box {
    width: 8vh;
    height: 8vh;
  }

  .keyboard-container {
    max-width: 70%;
  }

  .key {
    width: 3vw;
    height: 3vw;
  }

  .key-action {
    width: 4.5vw;
    height: 3vw;
  }
}