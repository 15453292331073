.toggleSwitch-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: #73b76d;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: var(--correct);
}

.hardmode {
  position: absolute;
  width: 50px;
  margin-top: 10vh;
  display: flex;
  text-align: center;
  font-size: small;
  font-weight: bold;
  white-space: wrap;
  opacity: 0;
  transition: all 500ms ease-in-out;
  transform: translateY(20%)
}

.toggleSwitch-container:hover .hardmode {
  opacity: 1;
}

@media (min-width: 480px) {
  .hardmode {
    transform: translateY(30%)
  }
}